<template>
  <navbar>
    <div class="navbar-brand" @click="$router.go(-1)">
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.6666 9.66667V12.3333H5.66663L13 19.6667L11.1066 21.56L0.546631 11L11.1066 0.440002L13 2.33334L5.66663 9.66667H21.6666Z"
          fill="#F2F2F2"
        />
        />
      </svg>
    </div>
    <h6 class="title-navbar">
      Nominal dan Pembayaran
    </h6>
  </navbar>

  <div id="list-method-payment" class="select-nominal_wrapper" @click="handleClickOutside">
    <div class="card-select_nominal">
      <div class="wallet-wrapper">
        <h6 class="title-wallet">
          <b>E-Wallet (Pembayaran Otomatis)</b>
        </h6>
        <div
          v-for="(ewallet, index) in ewallets"
          :key="index"
          class="card card-payment"
          :style="
            methodPayment.paymentName === ewallet.nameBank
              ? 'border: 2px solid #F28F1D !important; border-radius: 8px !important;'
              : 'border: 0.5px solid #E0E0E0 !important; border-radius: 8px !important;'
          "
          @click="handleSelectPayment(ewallet)"
        >
          <div class="card-body card__body-payment">
            <label for="wallet_desc" class="payment_desc">{{ ewallet.nameBank }}</label>
            <img :src="ewallet.iconBank" alt="Icon Payment" class="icon__information" />
          </div>
        </div>
      </div>

      <div class="instants-wrapper">
        <h6 class="title-instants">
          <b>Pembayaran Instant (Diverfikasi otomatis)</b>
        </h6>
        <div
          v-for="(bank, index) in convensionalBanks"
          :key="index"
          class="card card-payment"
          :style="
            methodPayment.paymentName === bank.nameBank
              ? 'border: 2px solid #F28F1D !important; border-radius: 8px !important;'
              : 'border: 0.5px solid #E0E0E0 !important; border-radius: 8px !important;'
          "
          @click="handleSelectPayment(bank)"
        >
          <div class="card-body card__body-payment">
            <label for="payment_desc" class="payment_desc">{{ bank.nameBank }}</label>
            <img :src="bank.iconBank" alt="Icon Payment" class="icon__information" />
          </div>
        </div>
      </div>

      <div class="syariah-wrapper">
        <h6 class="title-syariah">
          <b>Transfer Bank (Transaksi diverifikasi manual 1x24jam)</b>
        </h6>
        <div
          v-for="(bank, index) in syariahBanks"
          :key="index"
          class="card card-payment"
          :style="
            methodPayment.paymentName === bank.nameBank
              ? 'border: 2px solid #F28F1D !important; border-radius: 8px !important;'
              : 'border: 0.5px solid #E0E0E0 !important; border-radius: 8px !important;'
          "
          @click="handleSelectPayment(bank)"
        >
          <div class="card-body card__body-payment">
            <label for="payment_desc" class="payment_desc">{{ bank.nameBank }}</label>
            <img :src="bank.iconBank" alt="Icon Payment" class="icon__information" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="list-select-nominal" class="select-nominal_wrapper" @click="handleClickOutside">
    <div class="card-select_nominal">
      <h6 class="title__nominal-card">
        Pilih Nominal Donasi
      </h6>
      <div class="card__wrapper">
        <div class="list-nominal_wrapper">
          <div
            v-for="(nominal, index) in nominals"
            :key="index"
            class="card card__nominal"
            :class="
              selectNominal.id === nominal.id && selectNominal.value !== null
                ? 'select__card-wrapper'
                : ''
            "
            @click="handleSelectNomial($event, nominal)"
          >
            <div class="card-body card__nominal-body">
              <span class="nominal__value nominal__value-selected">
                Rp. {{ format(nominal.value) }}
              </span>
            </div>
          </div>
        </div>

        <div class="input__nominal-wrapper">
          <h6 class="title__nominal-input">
            Nominal Donasi
          </h6>
          <span class="desc__nominal-input">minimal rp 10.000 s/d tidak terbatas</span>
          <div ref="cardInput" class="card card-input mt-4" @click="handleInputNominal($event)">
            <div class="card-body card__input-nominal">
              <span class="currency">Rp.</span>
              <input
                id="input-nominal"
                ref="inputNominal"
                v-model="nominalValue"
                type="text"
                class="form-control form__input-nominal"
                placeholder="300.000"
                autocomplete="off"
                @keyup="handleInputValueNominal"
              />
            </div>
          </div>
        </div>

        <div class="donation__wrapper">
          <div class="group__donation-wrapper">
            <button
              type="button"
              class="btn btn-warning btn-method"
              :disabled="disabledSave"
              @click="handleSaveNominal"
            >
              Simpan
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container" style="padding-bottom:10px;">
    <h6 class="title-form_transaction mt-4 mb-2">
      Lengkapi Data Berikut Untuk Berdonasi, Kemudian Klik Lanjut Pembayaran
    </h6>
    <p class="title-form_transaction mb-2">
      Pilih Donasi dan Metode Pembayaran
    </p>

    <div class="card card__next-payment">
      <div class="card-body next__payment-body">
        <div ref="cardInput" class="card card-input mb-2">
          <div
            id="card-input-nominal"
            class="card-body card__input-nominal"
            @click="handleInputNominal($event)"
          >
            <span class="currency">Rp.</span>
            <input
              id="form-input-nominal"
              v-model="selectNominal.value"
              type="text"
              class="form-control form__input-nominal"
              placeholder="300.000"
              autocomplete="off"
              @keyup="handleInputValueNominal"
            />
          </div>
        </div>
        <button
          id="button-method-payment"
          type="button"
          class="card-method_payment"
          @click="handleInputNominal($event)"
        >
          <div
            v-if="methodPayment.paymentName !== ''"
            style="width:50px; margin-left:15px;"
          >
            <img
              :src="methodPayment.paymentLogo"
              :alt="methodPayment.paymentName"
              style="width:100%; height:100%;"
            />
          </div>
          <span class="method__name-payment">
            {{ methodPayment.paymentName === '' ? 'Metode Pembayaran' : methodPayment.paymentName }}
          </span>
          <!-- <p id="button-select-method" class="edit__method-payment mb-0" @click.prevent="handleInputNominal($event)">{{methodPayment.paymentName === '' ? 'Pilih' : 'Ubah'}}</p> -->
          {{ methodPayment.paymentName === '' ? 'Pilih' : 'Ubah' }}

          <!-- <div class="card-body d-flex align-items-center justify-content-between" style="height:30px;">
                <div class="d-flex align-items-center">
                    
                </div>
              </div> -->
        </button>
      </div>
    </div>
  </div>
  <div class="container">
    <p v-if="accessToken == null" class="title-form_transaction mb-2">
      Silahkan
      <router-link :to="`/login?redirectUrl=${$route.fullPath}`">
        Login
      </router-link>
      Atau Atau Lengkapi Data Berikut
    </p>

    <div class="card card__data-pewakif">
      <div class="card-body card__body-data">
        <div class="data__pewakif-wrapper">
          <div v-if="accessToken !== null">
            <div
              v-if="
                (accessToken !== null && accountData.fullname !== null) ||
                  accountData.fullname !== ''
              "
            >
              <p class="font-weight-bold">
                {{ accountData.fullname }}
              </p>
            </div>
            <div
              v-if="(accessToken !== null && accountData.phone !== '') || accountData.email !== ''"
              class="mb-2"
            >
              <div class="d-flex align-items-center">
                <p class="text-muted mx-1" style="font-size:12px;">
                  {{ accountData.phone }}
                </p>
                <p style="font-size:12px;">
                  {{ accountData.phone === null || accountData.email === '' ? ' ' : ' - ' }}
                </p>
                <p class="text-muted mx-1" style="font-size:12px;">
                  {{ accountData.email }}
                </p>
              </div>
            </div>
          </div>
          <div
            v-if="
              (accessToken === null && accountData.fullname === null) || accountData.fullname === ''
            "
            class="input-group input__group mb-2"
          >
            <span id="basic-addon1" class="input-group-text  input__login-transaction">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.9599 13.7101C15.8896 13.3722 15.5587 13.1552 15.2206 13.2256C14.8827 13.2959 14.6658 13.6269 14.7362 13.9648C14.7767 14.1595 14.7284 14.359 14.6038 14.5123C14.5316 14.6011 14.3706 14.75 14.1038 14.75H1.89713C1.63032 14.75 1.46935 14.6011 1.39716 14.5123C1.27251 14.359 1.22426 14.1595 1.26479 13.9648C1.91341 10.8494 4.66138 8.57356 7.83479 8.49681C7.88976 8.49894 7.94498 8.5 8.00048 8.5C8.05616 8.5 8.1116 8.49891 8.16679 8.49678C10.4022 8.55041 12.4683 9.68262 13.7195 11.5487C13.9117 11.8354 14.2999 11.9119 14.5867 11.7197C14.8734 11.5275 14.95 11.1393 14.7577 10.8526C13.7322 9.32306 12.243 8.21088 10.5451 7.65203C11.5798 6.87613 12.2505 5.63991 12.2505 4.25C12.2505 1.90656 10.3439 0 8.00048 0C5.65701 0 3.75048 1.90656 3.75048 4.25C3.75048 5.64075 4.42198 6.87762 5.45776 7.65347C4.50876 7.96591 3.61829 8.45206 2.83891 9.09313C1.40979 10.2687 0.416133 11.9083 0.0410387 13.7101C-0.0765238 14.2747 0.06432 14.8545 0.427382 15.301C0.788664 15.7452 1.32438 16 1.89713 16H14.1038C14.6766 16 15.2123 15.7452 15.5735 15.301C15.9367 14.8545 16.0775 14.2747 15.9599 13.7101ZM5.00048 4.25C5.00048 2.59578 6.34626 1.25 8.00048 1.25C9.6547 1.25 11.0005 2.59578 11.0005 4.25C11.0005 5.85328 9.73626 7.16684 8.15232 7.24619C8.10179 7.24525 8.05116 7.24478 8.00048 7.24478C7.95004 7.24478 7.8996 7.24528 7.84923 7.24622C6.26501 7.16719 5.00048 5.8535 5.00048 4.25Z"
                  fill="#828282"
                />
              </svg>
            </span>
            <input
              v-model="dataPewakif.fullname"
              type="text"
              class="form-control form-input_transaction"
              placeholder="Nama Lengkap"
              aria-label="Nama Lengkap"
              aria-describedby="basic-addon1"
              @keyup="handleKeyupFullname($event)"
            />
            <div class="invalid-feedback d-block">
              {{ messageError.fullname }}
            </div>
          </div>
          <div
            v-if="accessToken === null && accountData.phone === null"
            class="input-group input__group mb-2"
          >
            <span id="basic-addon1" class="input-group-text  input__login-transaction">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.9987 12.8923C15.9764 12.2734 15.7186 11.6958 15.2728 11.266C14.3999 10.4243 13.6686 9.93845 12.9713 9.73695C12.0105 9.45926 11.1205 9.71176 10.3263 10.4875C10.3251 10.4886 10.3239 10.4898 10.3227 10.491L9.47771 11.3297C8.94961 11.032 7.92274 10.3784 6.8133 9.26892L6.73105 9.18673C5.62143 8.07714 4.96783 7.05008 4.67042 6.52258L5.50917 5.67754C5.51036 5.67636 5.51155 5.67517 5.51274 5.67395C6.28839 4.87976 6.54089 3.98983 6.26324 3.02892C6.06171 2.33164 5.57586 1.60033 4.73417 0.727483C4.30436 0.281764 3.72683 0.0239515 3.10792 0.00163904C2.48864 -0.0207047 1.89439 0.194858 1.43352 0.608545L1.41558 0.62467C1.40727 0.632139 1.39914 0.639858 1.39121 0.647764C0.473738 1.56523 -0.0072 2.84964 0.000331252 4.36217C0.013175 6.93161 1.42533 9.86998 3.7778 12.2224C4.22139 12.666 4.72555 13.105 5.27624 13.5273C5.55014 13.7373 5.94246 13.6855 6.15249 13.4116C6.36255 13.1376 6.31074 12.7453 6.0368 12.5353C5.52946 12.1463 5.0668 11.7436 4.66167 11.3386C2.5368 9.21373 1.26152 6.60342 1.2503 4.35595C1.24452 3.19108 1.59492 2.2188 2.26377 1.54298L2.26855 1.53867C2.72283 1.13089 3.41061 1.1557 3.83442 1.59517C5.45252 3.27323 5.33536 4.06545 4.62036 4.79864L3.4618 5.96589C3.28011 6.14895 3.22952 6.42483 3.33442 6.66045C3.36383 6.72651 4.07536 8.29879 5.84739 10.0708L5.92968 10.153C7.70149 11.9248 9.2738 12.6363 9.33986 12.6657C9.57543 12.7707 9.85136 12.7201 10.0344 12.5384L11.2017 11.3798C11.935 10.6648 12.7272 10.5477 14.4051 12.1658C14.8446 12.5895 14.8694 13.2773 14.4617 13.7315L14.4573 13.7365C13.787 14.3999 12.8251 14.75 11.6729 14.75C11.6634 14.75 11.6539 14.75 11.6444 14.7499C10.7234 14.7453 9.65693 14.4929 8.56033 14.0199C8.24346 13.8831 7.87561 14.0292 7.73892 14.3462C7.60221 14.6632 7.7483 15.0309 8.06527 15.1676C9.33283 15.7144 10.5349 15.9944 11.6381 15.9999C11.6499 16 11.6615 16 11.6732 16C13.1705 16 14.4422 15.5194 15.3525 14.609C15.3605 14.6011 15.3681 14.593 15.3756 14.5847L15.3918 14.5666C15.8055 14.1058 16.021 13.5112 15.9987 12.8923Z"
                  fill="#828282"
                />
              </svg>
            </span>
            <input
              v-model="dataPewakif.phone"
              type="text"
              class="form-control form-input_transaction"
              placeholder="Nomor Ponsel"
              aria-label="Nomor Ponsel"
              aria-describedby="basic-addon1"
              @keyup="handleKeyupPhone($event)"
            />
            <div class="invalid-feedback d-block">
              {{ messageError.phone }}
            </div>
          </div>
          <div
            v-if="(accessToken == null && accountData.email === null) || accountData.email === ''"
            class="input-group input__group mb-2"
          >
            <span id="basic-addon1" class="input-group-text  input__login-transaction">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.8274 5.1861C15.4936 4.33629 14.8816 3.11014 13.8613 2.09465C12.8446 1.08265 11.6314 0.488153 10.7927 0.167592C9.87199 -0.184313 8.82572 0.0374989 8.12728 0.732652L5.67298 3.17546C4.94545 3.89955 4.73576 5.01373 5.15117 5.94794C5.27998 6.23769 5.42007 6.5211 5.56976 6.79491L0.183062 12.1816C0.0658435 12.2988 0 12.4578 0 12.6235V15.3735C0 15.7187 0.279811 15.9985 0.624998 15.9985H3.37499C3.72017 15.9985 3.99998 15.7187 3.99998 15.3735V14.2485L5.12498 14.2485C5.47017 14.2485 5.74998 13.9687 5.74998 13.6235V12.4985H6.87497C7.22016 12.4985 7.49997 12.2187 7.49997 11.8735C7.49997 11.5284 7.22016 11.2485 6.87497 11.2485H5.12498C4.77979 11.2485 4.49998 11.5284 4.49998 11.8735V12.9985L3.37499 12.9985C3.0298 12.9985 2.74999 13.2784 2.74999 13.6235V14.7485H1.25V12.8824L6.79232 7.34013C6.99594 7.1365 7.0341 6.82016 6.88475 6.57397C6.66816 6.21697 6.46919 5.83548 6.29341 5.44007C6.08719 4.97629 6.19226 4.42226 6.55485 4.06136L9.00912 1.61859C9.35953 1.26987 9.88446 1.15865 10.3466 1.33521C11.0722 1.61259 12.1181 2.12315 12.9795 2.98058C13.8463 3.8432 14.3739 4.90454 14.6639 5.64298C14.8449 6.10385 14.7349 6.62744 14.3838 6.97694L11.8998 9.44924C11.5381 9.80921 10.9982 9.91446 10.5243 9.71737C10.1335 9.55481 9.75496 9.36168 9.39934 9.1434C9.10518 8.96284 8.72031 9.05496 8.53975 9.34915C8.35919 9.64334 8.45128 10.0282 8.74547 10.2088C9.15646 10.461 9.59346 10.684 10.0443 10.8715C10.9862 11.2633 12.0607 11.0527 12.7816 10.3352L15.2655 7.86294C15.9701 7.16172 16.1906 6.11101 15.8274 5.1861Z"
                  fill="#828282"
                />
              </svg>
            </span>
            <input
              v-model="dataPewakif.email"
              type="text"
              class="form-control form-input_transaction"
              placeholder="Alamat Email (Optional)"
              aria-label="Alamat Email (Optional)"
              aria-describedby="basic-addon1"
            />
            <div class="invalid-feedback d-block">
              {{ messageError.email }}
            </div>
          </div>
          <div class="input-group input__group d-block">
            <span class="title-form_transaction">Munculkan donasi sebagai</span>
            <div class="form-check form-check_transaction">
              <input
                id="flexCheckChecked"
                class="form-check-input"
                type="checkbox"
                value=""
                @input="handleCheckHambaAllah"
              />
              <label class="form-check-label form-check_transaction" for="flexCheckChecked">
                Hamba Allah
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container container__message" style="padding-bottom:80px;">
    <h6 class="title__next-payment">
      Pesan Dukungan
    </h6>

    <div class="card card__message">
      <div class="card-body card__body-message">
        <textarea
          ref="note"
          v-model="note"
          cols="30"
          rows="5"
          maxlength="120"
          class="form-control form-message"
          placeholder="Tulis pesan atau do'amu untuk diaminkan"
          :disabled="disabled"
          @keyup="handleKeyupMessage"
        />
      </div>
      <div class="card-footer card__footer-message">
        {{ maxLength }}
        karakter
      </div>
    </div>
  </div>
  <div class="donation__wrapper payment-donation_wrapper">
    <div class="group__donation-wrapper payment-donation_group">
      <button
        type="button"
        class="btn btn-warning btn-pay"
        :disabled="disabledPayDonation"
        @click="handleCreateDonation"
      >
        Bayar Donasi
      </button>
    </div>
  </div>
</template>

<script>
import Navbar from './../components/TransactionComponent/NavbarTransaction.vue'

import swal from 'sweetalert2'

export default {
  name: 'Transaction',
  components: { Navbar },
  data() {
    return {
      nominals: [
        {
          id: 1,
          value: 50000,
          best: false
        },
        {
          id: 2,
          value: 100000,
          best: false
        },
        {
          id: 3,
          value: 200000,
          best: false
        },
        {
          id: 4,
          value: 300000,
          best: false
        },
        {
          id: 5,
          value: 500000,
          best: false
        },
        {
          id: 6,
          value: 1000000,
          best: false
        }
      ],
      ewallets: [
        {
          id: '18',
          nameBank: 'QRIS',
          iconBank: require('../assets/svg/icon-qris.svg'),
          code: 'qris',
          gate: 'tripay',
          accountNumber: null
        },
        // {
        //   id: '10',
        //   nameBank: 'Gopay',
        //   iconBank: require('../assets/svg/gopay-seeklogo.com-5.svg'),
        //   code: 'gopay',
        //   gate: 'midtrans',
        //   accountNumber: null
        // }
      ],
      convensionalBanks: [
        {
          id: '12',
          nameBank: 'Permata Virtual Account',
          iconBank: require('../assets/svg/icon-permata-bank.svg'),
          code: 'PERMATAVA',
          gate: 'tripay',
          accountNumber: null
        },
        {
          id: '13',
          nameBank: 'BNI Virtual Account',
          iconBank: require('../assets/svg/icon-bni-bank.svg'),
          code: 'BNIVA',
          gate: 'tripay',
          accountNumber: null
        },
        {
          id: '14',
          nameBank: 'BRI Virtual Account',
          iconBank: require('../assets/svg/icon-bri-bank.svg'),
          code: 'BRIVA',
          gate: 'tripay',
          accountNumber: null
        },
        {
          id: '15',
          nameBank: 'Mandiri Virtual Account',
          iconBank: require('../assets/svg/icon-mandiri-bank.svg'),
          code: 'MANDIRIVA',
          gate: 'tripay',
          accountNumber: null
        },
        {
          id: '16',
          nameBank: 'BCA Virtual Account',
          iconBank: require('../assets/svg/icon-bca-bank.svg'),
          code: 'BCAVA',
          gate: 'tripay',
          accountNumber: null
        },
        {
          id: '17',
          nameBank: 'Alfamart',
          iconBank: require('../assets/svg/icon-alfamart.svg'),
          code: 'ALFAMART',
          gate: 'tripay',
          accountNumber: null
        }
      ],
      syariahBanks: [
        {
          id: '21',
          nameBank: 'Transfer Bank Syariah Mandiri',
          iconBank: require('../assets/logo_bsi.png'),
          code: 'bsi_az_account',
          gate: null,
          accountNumber: '3000202206',
          ownerName: 'Yayasan Amazing Sedekah Indonesia'
        },
        {
          id: '22',
          nameBank: 'Transfer BRI',
          iconBank: require('../assets/svg/icon-bri-bank.svg'),
          code: 'bri_az_account',
          gate: null,
          accountNumber: '033001000882566',
          ownerName: 'Yayasan Amazing Sedekah Indonesia'
        },
        {
          id: '02',
          nameBank: 'Transfer CIMB Niaga Syariah',
          iconBank: require('../assets/svg/icon-cimb-syariah-bank.svg'),
          code: 'cimb_syariah_account',
          gate: null,
          accountNumber: '762369272300',
          ownerName: 'Yayasan Amazing Sedekah Indonesia'
        },
        {
          id: '23',
          nameBank: 'Transfer Mandiri',
            iconBank: require('../assets/svg/icon-mandiri-bank.svg'),
          code: 'cimb_syariah_account',
          gate: null,
          accountNumber: '1270028092029',
          ownerName: 'Yayasan Amazing Sedekah Indonesia'
        }
        // {
        //   id: '03',
        //   nameBank: 'Transfer BCA',
        //   iconBank: require('../assets/svg/icon-bca-bank.svg'),
        //   code: 'bca_account',
        //   gate: null,
        //   accountNumber: '5865321225',
        //   ownerName: 'Nanu Utama'
        // },
        // {
        //   id: '04',
        //   nameBank: 'Transfer BRI',
        //   iconBank: require('../assets/svg/icon-bri-bank.svg'),
        //   code: 'bri_account',
        //   gate: null,
        //   accountNumber: '7317-01-004937-50-0',
        //   ownerName: 'Nanu Utama'
        // },
        // {
        //   id: '05',
        //   nameBank: 'Transfer Mandiri',
        //   iconBank: require('../assets/svg/icon-mandiri-bank.svg'),
        //   code: 'mandiri_account',
        //   gate: null,
        //   accountNumber: '127-00-1063427-5',
        //   ownerName: 'Nanu Utama'
        // }
      ],
      selectNominal: {
        id: null,
        value: null,
        best: null
      },
      dataPewakif: {
        fullname: '',
        email: '',
        phone: '',
        // eslint-disable-next-line @typescript-eslint/camelcase
        display_as_name: null
      },
      accountData: {
        fullname: '',
        email: '',
        phone: null
      },
      messageError: {
        fullname: '',
        phone: '',
        email: ''
      },
      methodPayment: {
        paymentName: '',
        paymentId: '',
        paymentCode: '',
        accountNumber: '',
        paymentLogo: ''
      },
      maxLength: 0,
      disabled: false,
      inputValue: 0,
      nominalValue: '',
      note: '',
      accessToken: null
    }
  },
  computed: {
    disabledSave() {
      if (this.nominalValue === '' || parseInt(this.nominalValue.replace(/\./g, '')) < 10000) {
        return true
      }
      return false
    },
    disabledPayDonation() {
      // if (this.accessToken !== null ) {
      //     if (this.accountData.phone === null || this.accountData.phone === ''
      //     || this.accountData.fullname === null || this.accountData.fullname === ''
      //     || this.selectNominal.value === null || this.methodPayment.paymentId === null) {
      //         return true
      //     }else{
      //         return false
      //     }

      //         }
      //     }

      // }
      if (
        this.dataPewakif.fullname === '' ||
        this.dataPewakif.phone === '' ||
        this.dataPewakif.phone === null ||
        this.dataPewakif.phone.length <= 7 ||
        this.selectNominal.value === null ||
        this.methodPayment.paymentId === ''
      ) {
        return true
      }

      return false
    }
  },
  mounted() {
    this.isAccessToken()
  },
  methods: {
    isAccessToken() {
      if (process.browser) {
        if (localStorage.getItem('access_token')) {
          this.accessToken = localStorage.getItem('access_token')
          const accountData = JSON.parse(localStorage.getItem('account_data'))
          const firstName =
            accountData.detail.first_name !== null ? accountData.detail.first_name : ''
          const lastName = accountData.detail.last_name !== null ? accountData.detail.last_name : ''

          this.dataPewakif.fullname = firstName + ' ' + lastName
          this.dataPewakif.email = accountData.email
          this.dataPewakif.phone =
            accountData.detail.phone_number !== null ? accountData.detail.phone_number : null

          this.accountData = {
            fullname: firstName + ' ' + lastName,
            email: accountData.email,
            phone: accountData.detail.phone_number !== null ? accountData.detail.phone_number : null
          }
        } else {
          this.accessToken = null
          if (localStorage.getItem('user')) {
            const user = JSON.parse(localStorage.getItem('user'))
            
            this.dataPewakif.fullname = user.fullname
            this.dataPewakif.email = user.email
            this.dataPewakif.phone = user.phone === undefined ? user.phon_number : user.phone
            // // eslint-disable-next-line @typescript-eslint/no-unused-vars
            // const dataPhone = null
            // if (user.phone) {
            //   // eslint-disable-next-line no-const-assign
            //   dataPhone = user.phone
            // } else if (user.phon_number) {
            //   // eslint-disable-next-line no-const-assign
            //   dataPhone = user.phon_number
            // } else if (user.phone_number) {
            //   // eslint-disable-next-line no-const-assign
            //   dataPhone = user.phone_number
            // }

            // const objUser = {
            //   fullname: user.fullname,
            //   // eslint-disable-next-line @typescript-eslint/camelcase
            //   phon_number: !user.phone,
            //   email: user.email
            // }
            // localStorage.setItem('user', JSON.stringify(objUser))
          }
        }
      }
    },
    handleKeyupPhone(e) {
      if (this.dataPewakif.phone === '') {
        this.messageError.phone = 'Nomor ponsel tidak boleh kosong'
        e.target.required = true
      } else if (this.dataPewakif.phone.length <= 7) {
        this.messageError.phone = 'Nomor ponsel harus lebih dari 8 karakter'
        e.target.required = true
      } else {
        this.messageError.phone = ''
        e.target.required = false
      }
    },
    handleKeyupFullname(e) {
      if (this.dataPewakif.fullname === '') {
        this.messageError.fullname = 'Nama lengkap tidak boleh kosong'
        e.target.required = true
      } else {
        this.messageError.fullname = ''
        e.target.required = false
      }
    },
    format: value => (value + '').replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
    handleInputValueNominal() {
      this.nominalValue = this.format(this.nominalValue)
      this.$emit('input', (this.nominalValue + '').replace(/[^0-9]/g, ''))
      this.disabled = false
    },
    handleKeyupMessage(event) {
      const lengthValue = event.target.value
      this.maxLength = lengthValue.length
    },
    handleSelectNomial($event, nominal) {
      this.selectNominal = nominal
      this.selectNominal.value = this.format(this.selectNominal.value)
      this.nominalValue = ''
      const listNominalWrapper = document.querySelector('#list-select-nominal')
      listNominalWrapper.classList.remove('show-nominal_wrapper')
      document.body.style.overflow = 'initital'
      document.body.style.position = 'initial'
      document.body.style.height = 'auto'
      document.body.style.top = null
      document.body.style.left = null
      document.body.style.right = null
    },
    handleInputNominal(e) {
      if (e.target.id === 'form-input-nominal') {
        const listNominalWrapper = document.querySelector('#list-select-nominal')
        listNominalWrapper.classList.toggle('show-nominal_wrapper')
        document.body.style.overflow = 'initital'
        document.body.style.position = 'fixed'
        document.body.style.height = '100vh'
        document.body.style.top = '0px'
        document.body.style.left = '0px'
        document.body.style.right = '0px'
      } else if (e.target.id === 'button-method-payment') {
        const listMethodPayment = document.querySelector('#list-method-payment')
        listMethodPayment.classList.toggle('show-nominal_wrapper')
        document.body.style.overflow = 'initital'
        document.body.style.position = 'fixed'
        document.body.style.height = '100vh'
        document.body.style.top = '0px'
        document.body.style.left = '0px'
        document.body.style.right = '0px'
      } else if (e.target.id === 'button-select-method') {
        const listMethodPayment = document.querySelector('#list-method-payment')
        listMethodPayment.classList.toggle('show-nominal_wrapper')
        document.body.style.overflow = 'initital'
        document.body.style.position = 'fixed'
        document.body.style.height = '100vh'
        document.body.style.top = '0px'
        document.body.style.left = '0px'
        document.body.style.right = '0px'
      } else if (e.target.id === 'input-nominal') {
        this.selectNominal = {
          id: null,
          value: null,
          best: null
        }
      }
      // this.selectNominal = {
      //     id: null,
      //     value: null,
      //     best: null
      // }
      // this.$refs.cardInput.classList.toggle('select__card-wrapper')
      // this.$refs.inputNominal.focus()
    },
    handleSaveNominal() {
      this.selectNominal.value = this.nominalValue
      const listNominalWrapper = document.querySelector('#list-select-nominal')
      listNominalWrapper.classList.remove('show-nominal_wrapper')
      document.body.style.overflow = 'initital'
      document.body.style.position = 'initial'
      document.body.style.height = 'auto'
      document.body.style.top = null
      document.body.style.left = null
      document.body.style.right = null
    },
    handleClickOutside(e) {
      if (e.target.id === 'list-select-nominal') {
        const listNominalWrapper = document.querySelector('#list-select-nominal')
        listNominalWrapper.classList.remove('show-nominal_wrapper')
        document.body.style.overflow = 'initital'
        document.body.style.position = 'initial'
        document.body.style.height = 'auto'
        document.body.style.top = null
        document.body.style.left = null
        document.body.style.right = null
      } else if (e.target.id === 'list-method-payment') {
        const listMethodPayment = document.querySelector('#list-method-payment')
        listMethodPayment.classList.remove('show-nominal_wrapper')
        document.body.style.overflow = 'initital'
        document.body.style.position = 'initial'
        document.body.style.height = 'auto'
        document.body.style.top = null
        document.body.style.left = null
        document.body.style.right = null
      }
    },
    handleSelectPayment(value) {
      this.methodPayment = {
        paymentName: value.nameBank,
        paymentId: value.id,
        paymentCode: value.code,
        accountNumber: value.accountNumber,
        paymentLogo: value.iconBank
      }
      const listMethodPayment = document.querySelector('#list-method-payment')
      listMethodPayment.classList.remove('show-nominal_wrapper')
      document.body.style.overflow = 'initital'
      document.body.style.position = 'initial'
      document.body.style.height = 'auto'
      document.body.style.top = null
      document.body.style.left = null
      document.body.style.right = null
    },
    handleCheckHambaAllah(e) {
      if (e.target.checked) {
        // eslint-disable-next-line @typescript-eslint/camelcase
        this.dataPewakif.display_as_name = 'Hamba Allah'
      } else {
        // eslint-disable-next-line @typescript-eslint/camelcase
        this.dataPewakif.display_as_name = null
      }
    },
    handleAccessLogin() {
      this.$router.push({
        path: '/login',
        query: { redirectUrl: this.$route.fullPath }
      })
    },
    handleCreateDonation() {
      const fullName = this.dataPewakif.fullname ? this.dataPewakif.fullname.split(/\s+/) : null
      // eslint-disable-next-line @typescript-eslint/no-unused-vars

      let firstName
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      let lastName
      if (fullName.length > 2) {
        firstName = fullName.slice(0, -1).join(' ')
        lastName = fullName.pop()
      } else {
        firstName = fullName[0]
        lastName = null
      }

      const valueNominal =
        this.selectNominal.value === null
          ? this.nominalValue.replace(/\./g, '')
          : this.selectNominal.value.replace(/\./g, '')

      // const priceSplit = valueNominal.split(".");
      // priceSplit.pop();
      // const totalDonasi = priceSplit.join(".");

      if (this.accessToken !== null) {
        this.dataPewakif.fullname = this.accountData.fullname
        this.dataPewakif.email = this.accountData.email
        if (this.accountData.phone !== null) {
          this.dataPewakif.phone = this.accountData.phone
        }
      }

      swal.fire({
        title: '', // add html attribute if you want or remove
        text: 'Mohon Menunggu',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: function() {
          swal.showLoading()
        }
      })

      this.$http
        .post(`${process.env.VUE_APP_API_URL}donate`, {
          // eslint-disable-next-line @typescript-eslint/camelcase
          email:
            this.dataPewakif.email === null || this.dataPewakif.email === ''
              ? null
              : this.dataPewakif.email,
          // eslint-disable-next-line @typescript-eslint/camelcase
          first_name: firstName,
          // eslint-disable-next-line @typescript-eslint/camelcase
          last_name: lastName,
          // eslint-disable-next-line @typescript-eslint/camelcase
          phone_number:
            this.dataPewakif.phone !== '' ? this.dataPewakif.phone : this.accountData.phone,
          // eslint-disable-next-line @typescript-eslint/camelcase
          payment_channel_id: this.methodPayment.paymentId,
          // eslint-disable-next-line @typescript-eslint/camelcase
          wakaf_ids: [this.$route.params.uuid],
          total: valueNominal,
          note: this.note,
          // eslint-disable-next-line @typescript-eslint/camelcase
          display_as_name: this.dataPewakif.display_as_name
        })
        .then(response => {
          const data = response.data
          const objUser = {
            fullname: this.dataPewakif.fullname,
            // eslint-disable-next-line @typescript-eslint/camelcase
            phon_number: this.dataPewakif.phone,
            email: this.dataPewakif.email
          }
          if (process.browser) {
            if (!localStorage.getItem('user')) {
              localStorage.setItem('user', JSON.stringify(objUser))
            } else {
              localStorage.setItem('user', JSON.stringify(objUser))
            }
          }
          if (data.payment_gateway == 'midtrans') {
            this.handlePostCreatePayment(response.data)
          } else if (data.payment_gateway == 'tripay') {
            this.handlePostCreatePayment(response.data)
          } else {
            this.$router.push({
              path: `/detail-pembayaran/${data.transaction_code}`
            })
            swal.close()
            swal.hideLoading()
          }
        })
        .catch(error => {
          swal.close()
          swal.hideLoading()
          swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error
          })
        })
    },
    handlePostCreatePayment(data) {
      const transactionCode = data.transaction_code

      if (this.accessToken !== null) {
        this.dataPewakif.fullname = this.accountData.fullname
        this.dataPewakif.email = this.accountData.email
        if (this.accountData.phone !== null) {
          this.dataPewakif.phone = this.accountData.phone
        }
      }

      const fullName = this.dataPewakif.fullname ? this.dataPewakif.fullname.split(/\s+/) : null
      // eslint-disable-next-line @typescript-eslint/no-unused-vars

      let firstName
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      let lastName
      if (fullName !== null) {
        if (fullName.length > 2) {
          firstName = fullName.slice(0, -1).join(' ')
          lastName = fullName.pop()
        } else {
          firstName = fullName[0]
          lastName = null
        }
      } else {
        ;(firstName = 'Hamba Allah'), (lastName = null)
      }

      this.$http
        .post(`${process.env.VUE_APP_API_URL}donate/payment`, {
          // eslint-disable-next-line @typescript-eslint/camelcase
          transaction_code: transactionCode,
          // eslint-disable-next-line @typescript-eslint/camelcase
          transaction_time: data.transaction_date,
          // eslint-disable-next-line @typescript-eslint/camelcase
          transaction_due_date_time: data.transaction_due_date,
          // eslint-disable-next-line @typescript-eslint/camelcase
          email:
            this.dataPewakif.email === ''
              ? this.accountData.email === ''
                ? null
                : this.accountData.email
              : this.dataPewakif.email,
          // eslint-disable-next-line @typescript-eslint/camelcase
          first_name: firstName,
          // eslint-disable-next-line @typescript-eslint/camelcase
          last_name: lastName,
          // eslint-disable-next-line @typescript-eslint/camelcase
          phone_number:
            this.dataPewakif.phone !== '' ? this.dataPewakif.phone : this.accountData.phone,
          // eslint-disable-next-line @typescript-eslint/camelcase
          payment_channel_id: this.methodPayment.paymentId,
          // eslint-disable-next-line @typescript-eslint/camelcase
          total: data.total,
          // eslint-disable-next-line @typescript-eslint/camelcase
          wakaf_ids: [this.$route.params.uuid]
        })
        .then(() => {
          this.$router.push({
            path: `/detail-pembayaran/${transactionCode}`
          })
          swal.close()
          swal.hideLoading()
        })
        .catch(error => {
          swal.close()
          swal.hideLoading()
          if (error.response.status == 422) {
            swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.response.data.message
            })
          } else {
            swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error
            })
          }
        })
    }
  }
}
</script>
